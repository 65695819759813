<template>
    <div>
        <el-form :model="formData"
                 :rules="formRules"
                 ref="form"
                 size="mini"
                 :disabled="type === 'detail'">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'角色名称'" prop="roleId">
                        <el-select v-model="formData.roleId"
                                   filterable
                                   :placeholder="'请选择'"
                                   style="width: 100%">
                            <el-option v-for="item in keyValues.RoleList"
                                       :key="item.key"
                                       :label="item.value"
                                       :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'数据权限类型'" prop="dataIds">
                        <el-select v-model="formData.dataIds"
                                   filterable
                                   multiple
                                   :placeholder="'请选择'"
                                   style="width: 100%">
                            <el-option v-for="item in keyValues.StaffTypeList"
                                       :key="item.key"
                                       :label="item.value"
                                       :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'RoleDataManagementForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                roleId: '',
                dataIds: '',
                id: this.id
            },
            formRules: {
                roleId: [
                    {
                        required: true,
                        message: '请选择角色名称',
                        trigger: 'blur'
                    }
                ],
                dataIds: [
                    {
                        required: true,
                        message: '请选择数据权限类型',
                        trigger: 'blur'
                    }
                ]
            },
            keyValues: {
                StaffTypeList: [],
                RoleList: [],
            }
        };
    },

    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$enum('EDataPrivilege'),
                this.$client.getRoleListInfo(),
            ]);
            this.keyValues.StaffTypeList = data[0] || [];
            this.keyValues.RoleList = data[1] || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
            const {type, id} = this;
            //编辑
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getRoleDataPrivilege(this.id);
            }
        },
        getRoleDataPrivilege(id) {
            this.$client.getRoleDataPrivilege(id).then(data => {
                this.formData = data || {};
            });
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (this.type === 'edit') {
                    if (valid) {
                        this.$client
                            .updateRoleDataPrivilege(this.formData)
                            .then(() => {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                cb();
                            });
                    }
                } else if (valid) {
                    const tmp = {...this.formData};
                    delete tmp.id;
                    this.$client.addRoleDataPrivilege(tmp).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
}; </script>
<style lang="less" scoped>

</style>

