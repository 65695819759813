<template>
  <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
    <div class="ui-layout-content--full ui-form-actions">
      <el-button type="primary" size="mini" @click="addHandle">{{'新建'}}</el-button>
      <el-button type="primary" size="mini" @click="editHandle">{{'编辑'}}</el-button>
      <el-button type="primary" size="mini" @click="showDetail">{{'详情'}}</el-button>
      <el-button type="primary" size="mini" @click="deleteHandle">{{'删除'}}</el-button>
    </div>
    <div class="ui-layout-content--full ui-layout-flex--1">
      <ex-search-table-pagination
        ref="searchTable"
        :fetch="$client.getRoleDataPrivileges"
        :columns="columns"
        :formOptions="formOptions"
        @selection-change="selectHandle"
      ></ex-search-table-pagination>
    </div>
    <el-dialog
      width="700px"
      :title="dialog.title"
      v-if="dialog.visible"
      :visible.sync="dialog.visible"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      :append-to-body="true"
      :show-close="true"
    >
      <role-data-management-form ref="editForm" :id="dialog.currentId" :type="dialog.type"></role-data-management-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModalHandle" size="mini">
            {{dialog.type === 'detail' ? '关闭' : '取消'}}
        </el-button>
        <el-button
          type="primary"
          @click="submitHandle"
          size="mini"
          v-if="dialog.type !== 'detail'"
        >{{'确定'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import RoleDataManagementForm from './RoleDataManagementForm';
export default {
    name: 'RoleDataManagement',
    components: {
        RoleDataManagementForm
    },
    data() {
        return {
            RoleListInfo: [],
            searchRules: {},
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                tyle: ''
            }
        };
    },
    computed: {
        columns() {
            return [
                {
                    type: 'selection'
                },
                {
                    prop: 'roleName',
                    label: '角色名称'
                },
                {
                    prop: 'dataName',
                    label: '数据权限类型',
                    width: 450
                },
                {
                    prop: 'createdUser',
                    label: '创建人'
                },
                {
                    prop: 'createTime',
                    label: '创建时间'
                },
                {
                    prop: 'updateUser',
                    label: '更新人'
                },
                {
                    prop: 'updateTime',
                    label: '更新时间'
                }
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'roleId',
                        itemType: 'select',
                        filterable: true,
                        clearable: true,
                        label: '角色名称',
                        options: this.RoleListInfo
                    }
                ]
            };
        }
    },
    methods: {
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        addHandle() {
            this.dialog = {
                title: '新建',
                currentId: undefined,
                visible: true,
                type: 'add'
            };
        },
        editHandle() {
            if (this.handleValidate('edit')) {
                this.dialog = {
                    currentId: this.selectedRows[0].id,
                    title: '编辑',
                    visible: true,
                    type: 'edit'
                };
            }
        },
        deleteHandle() {
            if (this.handleValidate('delete')) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning'
                })
                    .then(() => {
                        const {selectedRows} = this;
                        const ids = selectedRows.map(item => item.id);
                        this.$client
                            .deleteRoleDataPrivilege(ids)
                            .then(() => {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                this.$refs.searchTable.searchHandler();
                            })
                            .catch(() => {
                                this.$message({
                                    message: '操作失败！',
                                    type: 'error'
                                });
                            });
                    });
            }
        },
        showDetail() {
            if (this.handleValidate('detail')) {
                this.dialog = {
                    currentId: this.selectedRows[0].id,
                    title: '详情',
                    visible: true,
                    type: 'detail'
                };
            }
        },
        handleValidate(type) {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
            if ((type === 'edit' || type === 'detail') && length > 1) {
                this.$message({
                    message: '只允许对一条进行操作',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    },
    created() {
        this.$client.getRoleListInfo().then(data => {
            this.RoleListInfo = data;
        });
    }
};
</script>
